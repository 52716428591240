(function($) {

	//preloader
	$(window).on('load', function () {
		var preloader_wrap = $('#preloader_wrap'),
			preloader = $('#preloader');
		preloader.fadeOut();
		preloader_wrap.delay(350).fadeOut('slow');
	});

	//on ready
	$(document).ready(function() {
		//run modals
		$('a.getModal').on('click', function(e){
			e.preventDefault();
			var target_modal = $(this).attr('href');
			$(target_modal).arcticmodal();
			return false;
		});

		//scroll to anchors
		$('.scrolling').click(function(e){
			e.preventDefault();
			var target = $(this).attr('href');
			$('html, body').animate({scrollTop: $(target).offset().top}, 500);
			return false; 
		});

		//input[name=name] only letters and spaces
		$('input[name=name]').on('keydown', function(e) {
			var key = event.keyCode;
			return ((key >= 65 && key <= 90) || key === 8 || key === 32);
		});
		
		//masked input
		$("input[name=phone]").mask("+7 (999) 999-99-99");

		// hide placeholder on focus and return it on blur
		$('input, textarea').focus(function () {
			$(this).data('placeholder', $(this).attr('placeholder'));
			$(this).attr('placeholder', '');
		});
		$('input, textarea').blur(function () {
			$(this).attr('placeholder', $(this).data('placeholder'));
		});
		
		//nav-toggle
		$('.nav-toggle').on('click', function() {
			$('.nav-toggle').toggleClass('active');
			$('.nav').toggleClass('open');
			$('.nav-toggle-out-nav').removeClass('notvisible');
			setTimeout(function() {
				if ($('.nav').hasClass('open')) {
					$('.nav-toggle-in-nav').removeClass('notvisible');
					$('.nav-toggle-out-nav').addClass('notvisible');
				} else {
					$('.nav-toggle-in-nav').addClass('notvisible');
				}
			}, 300);
		});
		
		//read more on hover (click on mobile)
		$('.index-news-list-item .desc').each(function() {
			var	$this = $(this),
				$thisOriginalHeight = $this.height(),
				heightToCrop = '145px',
				$thisParentItem = $this.parents('.index-news-list-item');
			
			$this.css('height', heightToCrop);
			
			$thisParentItem.on('click', function() {
				$this.toggleClass('open');
				if ($this.hasClass('open')) {
					$this.stop().animate({ height: $thisOriginalHeight }, function(){
						$(this).css({height: 'auto'});
					});
				} else {
					$this.stop().animate({ height: heightToCrop });
				}
			});
			
		});
		
		//gallery-slider-wrap
		var gallerySlider = new Swiper('.gallery-slider-wrap', {
			pagination: '.gallery-slider-pagination',
			prevButton: '.gallery-slider-button-prev',
			nextButton: '.gallery-slider-button-next',
			keyboardControl: true,
			grabCursor: true,
			centeredSlides: true,
			loop: true,
			slideToClickedSlide: true,
			paginationClickable: true,
			initialSlide: 0,
			slidesPerView: 'auto',
			effect: 'coverflow',
			coverflow: {
				rotate: 0,
				stretch: 180,
				depth: 300,
				modifier: 1,
				slideShadows : false
			},
			breakpoints: {
				480: {
					coverflow: {
						rotate: 0,
						stretch: 100,
						depth: 300,
						modifier: 1,
						slideShadows : false
					}
				}
			}
		});
		
		// quotes-slider
		var $quotesSlider = $('.quotes-slider');
		var $quotesSliderNr = $('.quotes-slider-nr');
		$quotesSlider.slick({
			arrows: true,
			dots: false,
			infinite: true,
			speed: 300
		});
		$quotesSlider.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			$quotesSliderNr.text(i + ' / ' + slick.slideCount);
		});
		$quotesSlider.slick('slickGoTo', 0); //trigger init
		
		//park-map
		$('.hover-circle').on('mouseenter', function() {
			var spanClass = $(this).attr('class');
			var nr = spanClass.match(/\d+/)[0]; //get all number from class
			$('.park-map-labels .label').removeClass('active');
			$('.park-map-labels .label-'+nr).addClass('active');
		}).on('mouseleave', function() {
			$('.park-map-labels .label').removeClass('active');
		});
		$('.park-map-labels .label').on('mouseenter', function() {
			var spanClass = $(this).attr('class');
			var nr = spanClass.match(/\d+/)[0]; //get all number from class
			$('.hover-circle').removeClass('active');
			$('.hover-circle-'+nr).addClass('active');
		}).on('mouseleave', function() {
			$('.hover-circle').removeClass('active');
		});
		
		//teachers-list-item matchHeight
		if (window.matchMedia("(min-width: 768px)").matches) {
			$('.teachers-list-item .text-block').matchHeight({
				byRow: true,
				property: 'height',
				target: null,
				remove: false
			});
		}
		
		//news-list-item dotdotdot
		if (window.matchMedia("(min-width: 768px)").matches) {
			$('.news-list-item .desc, .news-list-item .title').dotdotdot({
				watch: true
			});
		}
		
		//teachers-list-item dotdotdot
		if (window.matchMedia("(min-width: 768px)").matches) {
			$('.teachers-list-item .desc').dotdotdot({
				watch: true
			});
		}
		
		//only-numbers
		$(".only-numbers").on("keypress", function(evt){
			var charCode = (evt.which) ? evt.which : event.keyCode
			if (charCode > 31 && (charCode < 48 || charCode > 57))
				return false;
			return true;
		});
		
		//select currency
		var selectedCurrency = $('.choose-currency .currency-block input:checked').val();
		$('.choose-currency .currency-block input[type=radio]').on('change', function () {
			selectedCurrency = $(this).val();
			$('#checked-donation-currency').text(selectedCurrency);
		});
		
		//custom-donation
		$('.custom-donation').on('click', function () {
			var self = $(this);
			if (!self.hasClass('active')) {
				self.addClass('active');
				$('.donation-form .price input[type=radio]').prop( "checked", false );
			}
			var summ = self.find('input').val();
			$('#checked-donation-value').text(summ);
		});
		$('.custom-donation input').on('keyup', function(){
			$('#checked-donation-value').text($(this).val());
		});
		$('.donation-form .price').not('.custom-donation').on('click', function() {
			$('.custom-donation').removeClass('active');
			var checked = $('.donation-form .price input[type=radio]:checked').val();
			$('#checked-donation-value').text(checked);
		});
		
		//type-of-donation-checkbox
		$('#type-of-donation-checkbox').on('change', function () {
			$('.one-time, .monthly').toggleClass('active');
		});
		
		//donation-email validation
		$('#donation-email').on('keyup', function () {
			var $this = $(this);
			var $thisParent = $(this).parents('.field-wrap');
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if ($this.val() == "") {
				$thisParent.removeClass("error").removeClass("validated")
			} else if(reg.test($this.val()) == false) {
				$thisParent.removeClass("validated");
				$thisParent.addClass("error");
			} else {
				$thisParent.removeClass("error");
				$thisParent.addClass("validated");
			}
		});
		
		//audioPlayer
		setTimeout(function(){
			$('audio').audioPlayer();
		}, 500);
		
		//audio toggle
		$('.audio-header').on('click', function(e){
			e.stopPropagation();
			$(this).parents('.audio-row').toggleClass('open');
			$(this).parents('.audio-row').find('.audio-container').stop().slideToggle();
		});
		
		//set time to audio player
		$('.audio-row .playlabel-item > span').on('click', function(){
			var time = $(this).data('time');
			var thePlayer = $(this).parents('.playlabels').siblings('.audio-player-wrap').find('.audioplayer');
			
			//time = time.split(":");
			//var min = parseInt(time[0]) * 60;
			//var sec = parseInt(time[1]);
			//var time_in_seconds = min + sec;
			
			var audio = $(this).parents('.audio-row').find('audio')[0];
			//audio.currentTime = time_in_seconds;
			
			audio.currentTime = parseInt(time);
			
			audio.play();
			$(this).attr('title', 'Pause').find('a').html('Pause');
			thePlayer.addClass('audioplayer-playing');
			
			$("audio").not(audio).each(function (index, audio) {
				$(audio).siblings('audioplayer-playing').attr('title', 'Play').find('a').html('Play');
				$(audio).parents('.audioplayer').removeClass('audioplayer-playing');
				
				audio.pause();
			});
		});
		
		//video toggle
		$('.video-header').on('click', function(e){
			e.stopPropagation();
			$(this).parents('.video-row').toggleClass('open');
			$(this).parents('.video-row').find('.video-container').stop().slideToggle();
		});
		
	}); //END on ready

})(jQuery);

// google map
var prev_infowindow = false;
function initMap() {
	var mapCanvas = document.getElementById('map');
	var address = mapCanvas.dataset.address;
	var latitude = mapCanvas.dataset.latitude;
	var longitude = mapCanvas.dataset.longitude;

	var myLatlng = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
	var mapOptions = {
		center: myLatlng,
		zoom: 17,
		disableDefaultUI: true
	}
	var map = new google.maps.Map(mapCanvas, mapOptions);

    var contentString =
        '<div class="map-content">' +
			'<div class="content-info">' +
				'<div class="my-content-info">' +
					'<span class="map-address-name">' + address + '</span>' +
				'</div>' +
				'<div class="my-content-info">' +
					'<span class="map-cords">' + latitude + ', </span>' +
					'<span class="map-cords">' + longitude + '</span>' +
				'</div>' +
			'</div>' +
		'</div>';

    var infoWindow = new google.maps.InfoWindow({
        content: contentString
	});
	
	var marker = new google.maps.Marker({
		position: myLatlng,
		map: map,
		icon: 'img/map-marker.png'
	});

    infoWindow.open(map, marker);

    google.maps.event.addListener(marker, 'click', function () {
        if (prev_infowindow) {
            prev_infowindow.close();
        }

        prev_infowindow = infoWindow;
        infoWindow.open(map, marker);

    });
}
if ($('#map').length > 0) {
	google.maps.event.addDomListener(window, 'load', initMap);
}